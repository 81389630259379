import React from 'react'
import Img from 'gatsby-image'
import { config, useSpring } from 'react-spring'
import Layout from '../components/layout'
import { AnimatedBox } from '../elements'
import SEO from '../components/SEO'
import { graphql } from 'gatsby'
import "../components/about.css"

const About = ({ data }) => {
  const pageAnimation = useSpring({
    config: config.slow,
    from: { opacity: 0 },
    to: { opacity: 1 },
  })

  return (
    <Layout>
      <SEO title="About | Long" desc="Send me a DM and lets connect!" />
      <AnimatedBox style={pageAnimation}>
        <p>
          <Img title="find me on IG @long.integer" fluid={data.banner.childImageSharp.fluid} alt="banner"/>

          <div className="box">
          <div className="row">
           <div className="cont-1">
              <h1 className="heading-num">MY STORY</h1>
              <div className="headerIntro smaller">{"If you don't see me playing around with"} <a href="https://pandas.pydata.org/">pandas</a> {" and "}<a href="https://www.python.org/">python</a> {" analysing data I am probably enjoying myself puzzling around with some JS and PHP or mentoring Generation Z on personal development and coding."}</div>
              <div className="headerIntro italic">{"currently studying life and Computer Engineering (M.Eng)"}</div>
              <div className="tech-tags">
                  <span className="tag">python</span>
                  <span className="tag">javascript</span>
                  <span className="tag">sql</span>
                  <span className="tag">php</span>
                  <span className="tag">web dev</span>
              </div>
            </div>
            <div className="cont-1 ">
                  <div className="name">改善 LONG PHAM</div>
                  <p className="name"></p>
                   <div className="headerIntro">{"What my friends say:"}</div>
                  <div className="headerIntro italic">{" 'He is an honest and extroverted person who is very interested in his fellow friends. His humorous and intelligent way of communicating with others makes him very likeable. Furthermore it is important for him to represent his own goals and needs as strongly as it is important for him to achieve the best for both sides in his relationships with other people.' "}</div>
                  <Img title="find me on IG @long.integer" fluid={data.longprofile.childImageSharp.fluid} alt="banner"/> 
                  <div className="tags">
                    <span className="tag">ginger</span>
                    <span className="tag">mindset</span>
                    <span className="tag">tech</span>
                    <span className="tag">dev</span>
                  </div>
              </div>
            </div> 
        </div>

          <div className="row passions">
            <h1 className="heading-num">PASSIONS</h1>
              
            <div className="col col-1">
              <Img title="everything is data" fluid={data.data.childImageSharp.fluid} alt="long" />
              <h3>DATA</h3>
              <p><div className="introduction left">{"Besides being a huge data enthusiast I absolutely love crunching and analysing data which work as a foundation of my digital strategies in the world of social media and real life."}</div></p>
            </div>
            <div className="col col-2">
              <Img title="mindsetmatters" fluid={data.longmindset.childImageSharp.fluid} alt="long" />
              <h3>MINDSET</h3>
              <p><div className="introduction mid">{"I realized finding your essence and pursuing true happiness - for yourself and with your friends was the real one piece for me. Today, accompanied by a huge amount of self-awareness and positivity I am chasing my passions and dreams."}</div></p>
            </div>
            <div className="col alignImgRight">
              <Img title="everybody has their own" fluid={data.story.childImageSharp.fluid} alt="long" />
              <h3>STORY</h3>
              <p><div className="introduction right">{"Apart from inspiring and supporting my  "}<a href="https://www.devinitelyhealthy.xyz">friends, family</a> {"  and "}<a href="https://www.herocollege.xyz">students</a> {" finding their passions, fulfilling my personal ambitions in becoming a data scientist is yet another chapter which is about to get written."}</div></p>
            </div>
          </div>

        </p>
      </AnimatedBox>
    </Layout>
  )
}

export default About
export const query = graphql`
  query {
  
    banner: file(relativePath: { eq: "data.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, maxHeight: 200) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    longprofile: file(relativePath: { eq: "long-view.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    engineering: file(relativePath: { eq: "long-about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    data: file(relativePath: { eq: "data4.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    story: file(relativePath: { eq: "long-story.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    },
 
    longmindset: file(relativePath: { eq: "long-mindset.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 500, maxHeight: 250) {
          ...GatsbyImageSharpFluid
        }
      }
    },

  }
`


{/* My favourite tech stack
            ---
            Languages: JavaScript, Python, SQL, PHP
            Frontend-Framework: Gatsby, jQuery, TailwindCSS
            Data Analysis: NumPy, Pandas, Matplotlib
            CMS: Shopify, Shopware, Wordpress
            Databases: MySQL, GraphQL
            Tools: Git, VSC, Slack

            ---
            Find me on instagram mostly @long.integer */}